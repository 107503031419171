import React from 'react';
import { Link } from 'gatsby';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import settings from '../../settings';

const topbanner = `${settings.IMAGES_BASE_URL}/images/404-images/bmw-404-banner.jpg`;

const BMW404 = () => (
  <Layout>
    <SEO title="BMW Performance Driving School | 404" />
    <div className="error-page">
      <LazyLoadImage effect="blur" src={topbanner} alt="BMW 404 Page" />
      <div className="container">
        <h1>404 error page</h1>
        <p>
          A BMW M850i is a beautiful thing, but seeing this particular one means you’ve landed on a missing page. Just
          follow one of the links below to get back to the action.
        </p>
        <div className="buttons">
          <Link to="/" aria-label="home-btn"><button className="btn btn-bordered">BMW Performance Center Home Page</button></Link>
          <Link to="/teenschool"><button className="btn btn-bordered">BMW Teen School</button></Link>
          <Link to="/driverschool"><button className="btn btn-bordered">BMW Driver’s School</button></Link>
          <Link to="/mschool"><button className="btn btn-bordered">BMW M School</button></Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default BMW404;